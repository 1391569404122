@import "google_fonts";

$body-font: 'Source Sans Pro', Helvetica, sans-serif;
$heading-font: 'Bitter', Georgia, serif;
$masthead-font: 'Bitter', Georgia, serif;

$main-color: #000000;
$link-color: #bc360a;
$accent-color: #e77500;
$secondary-bg-color: #000000;
$secondary-fg-color: #f58025;
$secondary-light-bg-color: #f7f5e7;
$primary-bg-color: #ee7f2d;
