html {
  font-size: $text-base-size;
}

body {
  font-family: $body-font;
  line-height: 1.5;
  display: grid;
  width: 100%;
  margin: 0 auto;
  grid-template-columns: 1fr minmax(0, $desktop) 1fr;
  grid-template-areas:
    "header header header"
    "nav nav nav"
    "n content b"
    "footer footer footer";
}

h1, h2, h3, h4, h5, h6 {
  font-family: $heading-font;
  margin: 0;
}

a {
  color: $link-color;
  text-decoration: none;
  &:hover, &:active, &:focus {
    color: $accent-color;
    text-decoration: underline;
  }
}

ul {
  list-style-type: square;
}

// Website footer is a modified Princeton orange on Princeton black
body > footer {
  grid-area: footer;
  background-color: $secondary-bg-color;
  color: $secondary-fg-color;
  padding: 2em 3em;
  ol {
    margin: 0 auto;
    width: max-content;
    list-style: none;
    padding: 0;
    li {
      &:after {
        content: " | ";
      }
      &:last-child:after {
        content: "";
      }
      display: inline;
    }
  }
  a, a:hover, a:focus, a:active {
    color: $secondary-fg-color;
  }
}

// Header is black on Princeton orange.
//
// Text centered, with group name in bigger bold on top and tag line below in
// slightly smaller font. Top and bottom margins match group name font size.
body > header {
  display: grid;
  grid-area: header;
  grid-template-columns: 1fr;
  grid-row-gap: 1em;
  grid-template-areas:
    "masthead"
    "tagline";

  background-color: $primary-bg-color;
  background-size: auto 100%;
  padding-top: 3.75em;
  padding-bottom: 3.75em;

  h1 {
    grid-area: masthead;
    font-family: $masthead-font;
    margin: 0 auto;
    font-size: 3.75em;
    text-align: center;
    width: 90%;
  }

  h2 {
    grid-area: tagline;
    margin: 0 auto;
    font-family: $body-font;
    font-size: 1.5em;
    font-weight: 300;
    font-style: italic;
    text-align: center;
    width: 90%;
  }
}

// Navigation bar below header transforms an ordered list into a horizontal
// nav-bar style.
//
// Links rendered in main font color normally, switching to Princeton black
// with Princeton orange font when hovering or active.
nav {
  grid-area: nav;
  display: grid;
  grid-template-columns: 1fr minmax(0, $desktop) 1fr;
  grid-template-areas: "a items b";
  margin: 0;
  background-color: $secondary-light-bg-color;
  ol {
    grid-area: items;
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      display: inline-block;
      text-align: center;
      a {
        display: inline-block;
        color: $main-color;
        text-decoration: none;
        font-size: 1em;
        padding: 1em 1.45em;

        &:hover {
          background-color: $secondary-bg-color;
          color: $secondary-fg-color;
          text-decoration: none;
        }
      }
    }
  }
}

// Basic main content styling
body > main, body > article {
  grid-area: content;
  width: 100%;
  margin: 0 auto;

  h1 {
    font-size: 3em;
    font-weight: normal;
  }

  h2 {
    font-size: 1.4em;
  }
}

// Display listings of group members as rows of triptics (three per row). Each
// person is in a `li` and contains at least two `p` child elements:
//
//  * First, one containing a square profile image. This is displayed as a
//  circle the height of the row.
//  * Name
//
// Alumni have an additional row for their graduation year and current
// affiliation.
ul.people {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-gap: 0.5em;
  padding-left: 0;

  li {
    display: grid;
    text-align: left;

    grid-template-rows: auto;
    grid-template-columns: 6em auto;
    grid-template-areas: "img name" ". .";

    margin: 0.5em 0;

    p {
        display: grid;
        grid-area: 1 / 1 / -1 / -1;

        grid-template-rows: auto;
        grid-template-columns: 6em auto;
        grid-template-areas: "img name" ". .";

        margin: 0;
    }

    img {
        grid-area: img;
        vertical-align: middle;
        height: 5em;
        width: 5em;
        object-fit: cover;
        border-radius: 100%;
        margin-right: 1em;
    }

    a {
        grid-area: name;
        line-height: 1em;
        margin: auto 0;
    }
  }
}

ul.past-members {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
}

// Publications are structured like so:
// <h3>Paper title</h3>
// <p class="authors">List Of, Authors In, The Paper.</p>
// <p class="venue">In proceedings of the 9th conference on web design</p>
// <p>
//   [<a href="/assets/papers/9-webdev.pdf">paper</a>]
//   [<a href="/assets/slides/9-webdev.pdf">presentation</a>]
// </p>
ul.publications, ol.publications {
  h3 {
    color: red;
    font-size: 1em;
    font-weight: normal;
    font-family: inherit;
    padding: 0;
    margin: 0;
  }
  p {
    padding: 0;
    margin: 0;

    &.venue {
      font-style: italic;
    }
  }
}

